<template>
  <!-- 面包屑 -->
  <el-breadcrumb separator-class="el-icon-arrow-right">
    <el-breadcrumb-item
      :key="route.name"
      v-for="route in matchedRouter"
      :to="route.path"
      >{{ route.meta.name }}</el-breadcrumb-item
    >
  </el-breadcrumb>
</template>

<script>
export default {
  created() {},
  computed: {
    matchedRouter() {
      const matchedRouter = this.$route.matched;
      return matchedRouter;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
